import React from "react"

import Layout from "../components/layout"

const NotFoundPage = () => (
  <Layout>
    <section>
      <h1>wikander.io</h1>
    </section>
  </Layout>
)

export default NotFoundPage
